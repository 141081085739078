import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, UrlTree } from '@angular/router';

import { PermissionService } from '@shared/services';

export const userPermissionGuard: CanActivateFn = (route: ActivatedRouteSnapshot): boolean | UrlTree => {
  const permissionService = inject(PermissionService);
  const router = inject(Router);

  const requiredRoles = route.data.permissions;
  const { isEqual, currentUserData } = permissionService.checkRoleAndUser(requiredRoles);

  if (!isEqual) {
    const metaData = permissionService.getActionForProhibited(currentUserData);
    if (metaData) {
      return router.createUrlTree([metaData.url], { queryParams: { id: metaData.id } });
    } else {
      return router.createUrlTree(['/home']);
    }
  }

  return isEqual;
};
